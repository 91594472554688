import DevilMayCry from 'assets/sounds/devilmaycry.mp3';

const album = 'netBloc Vol. 24: tiuqottigeloot';

export const initialTracks = [
  {
    url: DevilMayCry,
    duration: 322.612245,
    metaData: {
      title: 'Devil May Cry 5',
      //artist: 'Devil May Cry Orchestra',
      album,
    },
  },
];
