import { useWeb3Modal } from "@web3modal/react";
import { useEffect, useState } from "react";
import { useAccount, useDisconnect } from "wagmi";
//import startButton from 'assets/windowsIcons/start.png';
import "./connect.css";
import { switchNetwork } from "@wagmi/core";
import { mainnet, base } from "wagmi/chains";

export default function CustomButton({
  currentAccount,
  toggleMenu,
  isFooter = true,
  isWrongChain = false,
  isLauncher = false,
  isChangeToEth = false,
}) {
  const [loading, setLoading] = useState(false);
  const { open } = useWeb3Modal();
  const { isConnected } = useAccount();
  //const { disconnect } = useDisconnect();

  const onSwitchNetwork = async (id) => {
    const network = await switchNetwork({
      chainId: id,
    });
  };

  async function onOpen() {
    setLoading(true);
    await open();
    setLoading(false);
  }

  function onClick() {
    if (isConnected) {
      //disconnect();
      if (isWrongChain) {
        onSwitchNetwork(mainnet.id);
      }
      if (isFooter) {
        toggleMenu();
      }
      //disconnect();
    } else {
      onOpen();
    }
  }

  return (
    <div
      onClick={onClick}
      // onMouseDown={toggleMenu}
      disabled={loading}
    >
      <div className={isFooter ? "" : "winXP-wrap"}>
        <button className={isFooter ? "start-button" : "winXP-btn"}>
          {isFooter && <div className="icon"></div>}

          {isConnected
            ? isWrongChain
              ? "Wrong Chain"
              : currentAccount &&
                `${currentAccount.slice(0, 5)}...${currentAccount.slice(
                  -4,
                  currentAccount.length
                )}`
            : "Connect"}
        </button>
      </div>

      {/*<img
          src={startButton}
          //alt={loading ? 'Loading' : label}
          className="footer__start"
      />*/}
    </div>
  );
}
