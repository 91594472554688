import React, { useState } from 'react';
import styled from 'styled-components';

import { WindowDropDowns } from 'components';
import dropDownData from './dropDownData';

export default function Notepad({ onClose }) {
  const [docText, setDocText] = useState(
    'Sharks Bit Through The Undersea Cables And I’m Taking Potshots At The Sky And The Satellites Are Falling Around Our Feet.\n\nI Made A Beautiful Group Of Network Friends And Hit A Feedback Loop So hard That I Can’t Stop Winning. I’m NoClipping And Speaking In Fractals. I’m A Noblehearted Cyber Nomad Winning With My Beautiful Quatum Wealth Network For Sport Over Thousands Of Eschatons. My Nerves Have Arranged Themselves Into A Perfect Grid And I’m Cognizing At Maximum Efficiency. I’m Jumping Up And Down So My Network Is A Few Inches Closer To The ISS. I’ve Been Megadosing Human Growth All My Life For This Moment.\n\nThere Are Positive Quantum Wealth Network Comments Scrolling Through My Google Glasses And I’m Streaming The Success Of My Network To My Seven Thousand Online Friends (Always Growing) . I’m Accelerating I’m About To Reach Escape Velocity. Shed This Noisy Infosphere And Join Me In Edenic Quietude. This Is Our Final Reincarnation And I Refuse To Stop Winning.\n\nWe Can Cross The Chasm Together, Forever, My Beautiful Network. Will You Cross The Chasm With Me, NetWork? Will You Make Success Happen With Me?',
  );
  const [wordWrap, setWordWrap] = useState(true);

  function onClickOptionItem(item) {
    switch (item) {
      case 'Exit':
        onClose();
        break;
      case 'Word Wrap':
        setWordWrap(!wordWrap);
        break;
      case 'Time/Date':
        const date = new Date();
        setDocText(
          `${docText}${date.toLocaleTimeString()} ${date.toLocaleDateString()}`,
        );
        break;
      default:
    }
  }
  function onTextAreaKeyDown(e) {
    // handle tabs in text area
    if (e.which === 9) {
      e.preventDefault();
      e.persist();
      var start = e.target.selectionStart;
      var end = e.target.selectionEnd;
      setDocText(`${docText.substring(0, start)}\t${docText.substring(end)}`);

      // asynchronously update textarea selection to include tab
      // workaround due to https://github.com/facebook/react/issues/14174
      requestAnimationFrame(() => {
        e.target.selectionStart = start + 1;
        e.target.selectionEnd = start + 1;
      });
    }
  }

  return (
    <Div>
      <section className="np__toolbar">
        <WindowDropDowns items={dropDownData} onClickItem={onClickOptionItem} />
      </section>
      <StyledTextarea
        wordWrap={wordWrap}
        value={docText}
        onChange={e => setDocText(e.target.value)}
        onKeyDown={onTextAreaKeyDown}
        spellCheck={false}
      />
    </Div>
  );
}

const Div = styled.div`
  height: 100%;
  background: linear-gradient(to right, #edede5 0%, #ede8cd 100%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .np__toolbar {
    position: relative;
    height: 21px;
    flex-shrink: 0;
    border-bottom: 1px solid white;
  }
`;

const StyledTextarea = styled.textarea`
  flex: auto;
  outline: none;
  font-family: 'Lucida Console', monospace;
  font-size: 13px;
  line-height: 14px;
  resize: none;
  padding: 2px;
  ${props => (props.wordWrap ? '' : 'white-space: nowrap; overflow-x: scroll;')}
  overflow-y: scroll;
  border: 1px solid #96abff;
`;
