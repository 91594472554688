import ErrorBox from './ErrorBox';
import Winamp from './Winamp';
import Dashboard from './Dashboard';
import Staking from './Staking';
import Coins from "./Coins"
import Launcher from './Launcher';
import Documentation from './Documentation';
import Manifesto from './Manifesto';
import Telegram from './Telegram';
import Twitter from './Twitter';
import Dextools from './Dextools';
import error from 'assets/windowsIcons/897(16x16).png';
import dashboard from 'assets/windowsIcons/dashboard16.png';
import dashboardLarge from 'assets/windowsIcons/dashboard32.png';
import staking from 'assets/windowsIcons/stake.png';
import stakingLarge from 'assets/windowsIcons/stake.png';
import documentation from 'assets/windowsIcons/docs.png';
import documentationLarge from 'assets/windowsIcons/docs.png';
import manifesto from 'assets/windowsIcons/manifesto.png';
import manifestoLarge from 'assets/windowsIcons/manifesto.png';
import telegram from 'assets/windowsIcons/telegram16.png';
import telegramLarge from 'assets/windowsIcons/telegram32.png';
import twitter from 'assets/windowsIcons/twitter16.png';
import twitterLarge from 'assets/windowsIcons/twitter32.png';
import dextools from 'assets/windowsIcons/dextools16.png';
import dextoolsLarge from 'assets/windowsIcons/dextools32.png';
import launcher from 'assets/windowsIcons/launcher.png';
import coin from 'assets/windowsIcons/coin.png';
import music from 'assets/windowsIcons/music.png';



const gen = () => {
  let id = -1;
  return () => {
    id += 1;
    return id;
  };
};
const genId = gen();
const genIndex = gen();
export const defaultAppState = []; /*[
  {
    component: InternetExplorer,
    header: {
      title: 'Internet Explorer',
      icon: iePaper,
    },
    defaultSize: {
      width: 700,
      height: 500,
    },
    defaultOffset: {
      x: 130,
      y: 20,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    id: genId(),
    zIndex: genIndex(),
  },
  {
    component: Minesweeper,
    header: {
      title: 'Minesweeper',
      icon: mine,
    },
    defaultSize: {
      width: 0,
      height: 0,
    },
    defaultOffset: {
      x: 180,
      y: 170,
    },
    resizable: false,
    minimized: false,
    maximized: false,
    id: genId(),
    zIndex: genIndex(),
  },
  {
    component: Winamp,
    header: {
      title: 'Winamp',
      icon: winamp,
      invisible: true,
    },
    defaultSize: {
      width: 0,
      height: 0,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: false,
    maximized: false,
    id: genId(),
    zIndex: genIndex(),
  },
  {
    component: MyComputer,
    header: {
      title: 'My Computer',
      icon: computer,
    },
    defaultSize: {
      width: 660,
      height: 500,
    },
    defaultOffset: {
      x: 250,
      y: 40,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    id: genId(),
    zIndex: genIndex(),
  },
];*/

export const defaultIconState = [
  /*
  {
    id: 0,
    icon: ie,
    title: 'Internet Explorer',
    component: InternetExplorer,
    isFocus: false,
  },
  */
  /*
  {
    id: 1,
    icon: mine,
    title: 'Minesweeper',
    component: Minesweeper,
    isFocus: false,
  },
  */
  /*
  {
    id: 2,
    icon: computerLarge,
    title: 'My Computer',
    component: MyComputer,
    isFocus: false,
  },
  */
  /*
  {
    id: 2,
    //id: 3,
    icon: notepadLarge,
    title: 'Notepad',
    component: Notepad,
    isFocus: false,
  },
  */
  /*
  {
    //id: 5,
    id: 3,
    icon: paintLarge,
    title: 'Paint',
    component: Paint,
    isFocus: false,
  },
  */
  {
    // id: 4,
    id: 0,
    icon: dashboardLarge,
    title: 'Dashboard',
    component: Dashboard,
    isFocus: false,
  },
  {
    // id: 5,
    id: 1,
    icon: stakingLarge,
    title: 'Staking',
    component: Staking,
    isFocus: false,
  },
  {
    // id: 6,
    id: 2,
    icon: documentationLarge,
    title: 'Docs',
    component: Documentation,
    isFocus: false,
  },
  {
    // id: 7,
    id: 3,
    icon: manifestoLarge,
    title: 'Manifesto',
    component: Manifesto,
    isFocus: false,
  },
  {
    id: 4,
    icon: music,
    title: 'Wealth Music',
    component: Winamp,
    isFocus: false,
  },
  {
    id: 5,
    icon: telegramLarge,
    title: 'Telegram',
    component: Telegram,
    isFocus: false,
  },
  {
    id: 6,
    icon: twitterLarge,
    title: 'Twitter',
    component: Twitter,
    isFocus: false,
  },
  {
    id: 7,
    icon: dextoolsLarge,
    title: 'Dextools',
    component: Dextools,
    isFocus: false,
  },
  {
    id: 8,
    icon: launcher,
    title: 'Launcher',
    component: Launcher,
    isFocus: false,
  },
  {
    id: 9,
    icon: coin,
    title: '$HELPME',
    component: Coins,
    isFocus: false,
  }
];

export const appSettings = {
  Error: {
    header: {
      icon: error,
      title: 'C:\\',
      buttons: ['close'],
      noFooterWindow: true,
    },
    component: ErrorBox,
    defaultSize: {
      width: 380,
      height: 0,
    },
    defaultOffset: {
      x: window.innerWidth / 2 - 190,
      y: window.innerHeight / 2 - 60,
    },
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: true,
  },
  Dashboard: {
    header: {
      icon: dashboard,
      title: 'Quantum Wealth Dashboard',
    },
    component: Dashboard,
    defaultSize: {
      width: 1000,
      height: 500,
    },
    defaultOffset: {
      x: 400,
      y: 30,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Staking: {
    header: {
      icon: staking,
      title: 'QWN Staking',
    },
    component: Staking,
    defaultSize: {
      width: 1000,
      height: 700,
    },
    defaultOffset: {
      x: 100,
      y: 90,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Documentation: {
    header: {
      icon: documentation,
      title: 'Quantum Wealth Documentation',
    },
    component: Documentation,
    defaultSize: {
      width: 660,
      height: 500,
    },
    defaultOffset: {
      x: 270,
      y: 60,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Manifesto: {
    header: {
      icon: manifesto,
      title: 'Quantum Wealth Manifesto',
    },
    component: Manifesto,
    defaultSize: {
      width: 660,
      height: 500,
    },
    defaultOffset: {
      x: 270,
      y: 60,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Telegram: {
    header: {
      icon: telegram,
      title: 'Telegram',
    },
    component: Telegram,
    defaultSize: {
      width: 0,
      height: 0,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: true,
    maximized: false,
    multiInstance: false,
  },
  Twitter: {
    header: {
      icon: twitter,
      title: 'Twitter',
    },
    component: Twitter,
    defaultSize: {
      width: 0,
      height: 0,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: true,
    maximized: false,
    multiInstance: false,
  },
  Dextools: {
    header: {
      icon: dextools,
      title: 'Dextools',
    },
    component: Dextools,
    defaultSize: {
      width: 0,
      height: 0,
    },
    defaultOffset: {
      x: 0,
      y: 0,
    },
    resizable: false,
    minimized: true,
    maximized: false,
    multiInstance: false,
  },
  Winamp: {
    header: {
      icon: music,
      title: 'Wealth Music',
      invisible: true,
    },
    component: Winamp,
    defaultSize: {
      width: 0,
      height: 0,
    },
    defaultOffset: {
      x: `'50%'`,
      y: 0,
    },
    resizable: false,
    minimized: false,
    maximized: false,
    multiInstance: false,
  },
  Launcher: {
    header: {
      icon: launcher,
      title: 'Launcher',
    },
    component: Launcher,
    defaultSize: {
      width: 1000,
      height: 700,
    },
    defaultOffset: {
      x: 100,
      y: 90,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
  Coins: {
    header: {
      icon: coin,
      title: 'VoldemortMcCainRobotnik01Neko',
    },
    component: Coins,
    defaultSize: {
      width: 1000,
      height: 700,
    },
    defaultOffset: {
      x: 100,
      y: 90,
    },
    resizable: true,
    minimized: false,
    maximized: window.innerWidth < 800,
    multiInstance: true,
  },
};

export { ErrorBox, Manifesto, Documentation, Staking, Dashboard, Winamp };
