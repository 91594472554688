import React, { useState } from 'react';
import styled from 'styled-components';
import './docs.css';

import { WindowDropDowns } from 'components';
import dropDownData from './dropDownData';

export default function Notepad({ onClose }) {
  const [docText, setDocText] = useState('(Q,Q)');
  const [wordWrap, setWordWrap] = useState(true);

  /*function onClickOptionItem(item) {
    switch (item) {
      case 'Exit':
        onClose();
        break;
      case 'Word Wrap':
        setWordWrap(!wordWrap);
        break;
      case 'Time/Date':
        const date = new Date();
        setDocText(
          `${docText}${date.toLocaleTimeString()} ${date.toLocaleDateString()}`,
        );
        break;
      default:
    }
  }
  function onTextAreaKeyDown(e) {
    // handle tabs in text area
    if (e.which === 9) {
      e.preventDefault();
      e.persist();
      var start = e.target.selectionStart;
      var end = e.target.selectionEnd;
      setDocText(`${docText.substring(0, start)}\t${docText.substring(end)}`);

      // asynchronously update textarea selection to include tab
      // workaround due to https://github.com/facebook/react/issues/14174
      requestAnimationFrame(() => {
        e.target.selectionStart = start + 1;
        e.target.selectionEnd = start + 1;
      });
    }
  }*/

  return (
    <div className="Documentation">
      <div className="container">
        <h1>Quantum Wealth Network $QWN (Q,Q)</h1>
        <h2>
          To Our Beautiful Network : Thank You For Accelerating
          <br />
          <br />
          The Thesis Behind Our Network Is To Accelerate Every Soul To Quantum
          Wealth . This Can Only Be Achieved By Directly Interacting And
          Participating With Our Network Daily.&nbsp;
          <br />
          <br />
          Each Time You Positively Impact One Another You Are Making Your Mark
          On Something Beautiful.&nbsp;
          <br />
          <br />
          Accelerate Yourself And Your Network (Q,Q)
        </h2>
        <p>
          Quantum Wealth Network ($QWN) Is A Rebasing Token That Is Designed To
          Rebase Every 8 Hours, Referred To As An &quot;Epoch&quot;. The
          Infrastructure Around $QWN Encompasses Multiple Contracts, Including
          Those For Staking, Treasury Management, And Distribution.&nbsp;
          <br />
          <br />
        </p>
        <div className="mb10">
          <h2>1. Token Specification</h2>
          <h3>$QWN (Quantum Wealth Network Token)</h3>
          <ul>
            <li>
              <p>-Spiritually: Rebasing Token</p>
            </li>
            <li>
              <p>-Rebasing Period: Every 8 hours (1 epoch)</p>
            </li>
            <li>
              <p>-Mint Value: Each $QWN is mintable by 0.0001 ETH.</p>
            </li>
            <li>
              <p>Taxes:</p>
              <ul>
                <li>
                  <p className="pl5">-5% tax on buying</p>
                </li>
                <li>
                  <p className="pl5">-5% tax on selling</p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="mb10">
          <h2>2. Associated Contracts</h2>
          <div className="mb5">
            <h3>i) Staked Token (sQWN)</h3>
            <ul>
              <li>
                <p>
                  -Users can stake $QWN into the staking contract in exchange
                  for receiving sQWN.
                </p>
              </li>
            </ul>
          </div>
          <div className="mb5">
            <h3>ii) Staking Contract</h3>
            <ul>
              <li>
                <p>-Purpose: Allows users to stake their $QWN tokens.</p>
              </li>
              <li>
                <p>-Output: On staking, users receive sQWN tokens.</p>
              </li>
            </ul>
          </div>
          <div className="mb5">
            <h3>iii) Treasury Contract</h3>
            <ul>
              <li>
                <p>
                  -Primary Role: This contract holds and manages the ETH
                  reserves $QWN, ensuring its value.
                </p>
              </li>
              <li>
                <p>
                  -Redemption: ETH is redeemable through the treasury contract.
                </p>
              </li>
              <li>
                <p>Accrual:</p>
                <ul>
                  <li>
                    <p className="pl5">-Gathers ETH and LP tokens.</p>
                  </li>
                  <li>
                    <p className="pl5">
                      -Accrues value via a 5% tax on both buying and selling the
                      token.
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="mb5">
            <h3>iv) Distributor Contract</h3>
            <ul>
              <li>
                <p>
                  -Operation: At the end of every epoch, the distributor calls
                  out to the treasury.
                </p>
              </li>
              <li>
                <p>
                  -Output: New tokens are minted and sent to the staking
                  contract. These tokens are scheduled for rebasing at the
                  conclusion of the subsequent epoch.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="mb15">
          <h2>3. Workflow</h2>
          <ul>
            <li>
              <p>-Users purchase $QWN tokens.</p>
            </li>
            <li>
              <p>-A 5% buy tax is applied</p>
            </li>
            <li>
              <p>
                -Users can decide to stake their $QWN tokens via the staking
                contract.
              </p>
            </li>
            <li>
              <p>-Upon staking, users receive an equivalent amount of sQWN.</p>
            </li>
            <li>
              <p>
                -At the close of each epoch (every 8 hours), the distributor
                contract communicates with the treasury.
              </p>
            </li>
            <li>
              <p>
                -The treasury, in response, mints new tokens which are then
                allocated to the staking contract.
              </p>
            </li>
            <li>
              <p>
                -These newly minted tokens undergo rebasing at the end of the
                following epoch.
              </p>
            </li>
            <li>
              <p>
                -When users decide to sell their $QWN, a 5% sell tax is applied.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
/*
const Div = styled.div`
  height: 100%;
  background: linear-gradient(to right, #edede5 0%, #ede8cd 100%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .np__toolbar {
    position: relative;
    height: 21px;
    flex-shrink: 0;
    border-bottom: 1px solid white;
  }
`;

const StyledTextarea = styled.textarea`
  flex: auto;
  outline: none;
  font-family: 'Lucida Console', monospace;
  font-size: 13px;
  line-height: 14px;
  resize: none;
  padding: 2px;
  ${props => (props.wordWrap ? '' : 'white-space: nowrap; overflow-x: scroll;')}
  overflow-y: scroll;
  border: 1px solid #96abff;
`;*/
