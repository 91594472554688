import React, { useEffect, useState } from "react";
import "./launcher.css";
import { ethers, BigNumber } from "ethers";
import ConnectButton from "../../Web3Connection/CustomButton";
import { switchNetwork } from "@wagmi/core";

//ABIS
import qwnAbi from "../../abis/Qwn.json";
import sqwnAbi from "../../abis/Sqwn.json";
import stakingAbi from "../../abis/Staking.json";
import lpAbi from "../../abis/Lp.json";
import treasuryAbi from "../../abis/Treasury.json";
import rebaseQwaAbi from "../../abis/Qwa.json";
import nonRebaseQwaAbi from "../../abis/NonRebaseQwa.json";
import rebaseQwaBaseAbi from "../../abis/QwaBase.json";
import nonRebaseQwaBaseAbi from "../../abis/NonRebaseQwaBase.json";

//react toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const rebaseQwaAddressEth = "0x33B8930a44610E2969aacF3C9864A5aEAD2CB40c";
const nonRebaseQwaAddressEth = "0x6cEF2d4677a551EC32aD4971896bF28151954145";

const rebaseQwaAddressBase = "0x03c793511B835E41769432Eb3a3eF4af02AB648c";
const nonRebaseQwaAddressBase = "0x6eb7Fc24fEE70EFb7d2773c3dfF64555E31CD6c4";

//fixed amount of decimals without rounding it
const toFixedNoRoundParse = (number, precision = 4) => {
  number = Number(ethers.utils.formatUnits(number, 9));
  const factor = Math.pow(10, precision);
  const result = Math.floor(number * factor) / factor;

  if (result === 0) {
    return "0.0";
  }
  return result;
};

const toFixedNoRoundParseEther = (number, precision = 4) => {
  number = Number(ethers.utils.formatUnits(number, 18));
  const factor = Math.pow(10, precision);
  const result = Math.floor(number * factor) / factor;

  if (result === 0) {
    return "0.0";
  }
  return result;
};

const toFixedNoRoundParseUnit = (number, unit, precision = 18) => {
  number = ethers.utils.formatUnits(number, unit);
  number = number.split(".");
  const result = `${number[0]}.${number[1].substring(0, 2)}`;
  if (result === "0.00") {
    return "0.0";
  }
  return Number(result).toLocaleString("en-US");
};

const displayTimeTwoDigits = (number) => {
  number = number.toString();
  if (number.length < 2) {
    return "0" + number;
  }
  return number;
};

function isNumber(value) {
  if (typeof value === "string") {
    return !isNaN(value);
  }
}

export default function Launcher({
  onClose,
  signer,
  isWrongChain,
  addressEthBalance,
}) {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [ownerAddress, setOwnerAddress] = useState(null);
  const [ownerAddressError, setOwnerAddressError] = useState("");
  const [tokenName, setTokenName] = useState(null);
  const [tokenNameError, setTokenNameError] = useState(null);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const [tokenSymbolError, setTokenSymbolError] = useState(null);
  const [liquidity, setLiquidity] = useState(null);
  const [liquidityError, setLiquidityError] = useState(null);
  const [dexType, setDexType] = useState(["v2"]);
  const [creatorSupplyPercentage, setCreatorSupplyPercentage] = useState(null);
  const [creatorSupplyPercentageError, setCreatorSupplyPercentageError] =
    useState(null);
  const [backingTokens, setBackingTokens] = useState([null]);
  const [backingTokensError, setBackingTokensError] = useState("");
  const [backingTokensV3Fee, setBackingTokensV3Fee] = useState([
    ethers.utils.parseUnits("0", 4),
  ]);
  const [backingAmounts, setBackingAmounts] = useState([null]);
  const [backingAmountsError, setBackingAmountsError] = useState([]);
  const [currentBackingTokensNumber, setCurrentBackingTokensNumber] =
    useState(1);
  const [isSuccessPage, setIsSuccessPage] = useState(false);
  const [newTokenCreated, setNewTokenCreated] = useState("");
  const [tokenType, setTokenType] = useState("rebase");
  const [totalSupply, setTotalSupply] = useState(null);
  const [totalSupplyError, setTotalSupplyError] = useState(null);
  const [stakeMinPercentage, setStakeMinPercentage] = useState(null);
  const [stakeMinPercentageError, setStakeMinPercentageError] = useState(null);

  useEffect(() => {
    if (signer) {
      setCurrentAccount(signer._address);
    }
  }, [signer]);

  const onSwitchNetwork = async (id) => {
    const network = await switchNetwork({
      chainId: id,
    });
  };

  const onTokenTypeChange = (e) => {
    if (e.target.value === "rebase" || e.target.value === "non_rebase") {
      setTokenType(e.target.value);
    }
  };

  const onOptionChange = (e, index) => {
    if (e.target.value === "v2" || e.target.value === "v3") {
      setDexType((prev) => {
        prev[index] = e.target.value;
        return prev;
      });
      if (e.target.value === "v2") {
        setBackingTokensV3Fee((prev) => {
          prev[index] = ethers.utils.parseUnits("0", 4);
          return prev;
        });
      } else {
        setBackingTokensV3Fee((prev) => {
          prev[index] = ethers.utils.parseUnits("0.05", 4);
          return prev;
        });
      }
    }
    return;
  };

  const onClickAddInput = () => {
    setCurrentBackingTokensNumber((prev) => prev + 1);
    setBackingTokens((prev) => [...prev, null]);
    setBackingAmounts((prev) => [...prev, null]);
    setBackingTokensV3Fee((prev) => [...prev, ethers.utils.parseUnits("0", 4)]);
    setDexType((prev) => [...prev, "v2"]);
  };

  const onClickRemoveInput = () => {
    setCurrentBackingTokensNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      }
      return prev;
    });
    setBackingTokens((prev) => {
      const newArray = prev;
      newArray.splice(-1);
      return newArray;
    });

    setBackingAmounts((prev) => {
      const newArray = prev;
      newArray.splice(-1);
      return newArray;
    });
    setBackingTokensV3Fee((prev) => {
      const newArray = prev;
      newArray.splice(-1);
      return newArray;
    });
    setDexType((prev) => {
      const newArray = prev;
      newArray.splice(-1);
      return newArray;
    });
  };

  const onChangeOwnerAddress = (e) => {
    const text = e.target.value ? e.target.value : null;
    setOwnerAddressError("");
    setOwnerAddress(text);
  };

  const onChangeLiquidity = (e) => {
    const text = e.target.value ? e.target.value : null;
    setLiquidityError("");
    setLiquidity(text);
  };

  const onChangeCreatorSupplyPercentage = (e) => {
    const text = e.target.value ? e.target.value : null;
    setCreatorSupplyPercentageError("");
    setCreatorSupplyPercentage(text);
  };

  const onChangeTokenName = (e) => {
    const text = e.target.value ? e.target.value : null;
    setTokenNameError("");
    setTokenName(text);
  };

  const onChangeTokenSymbol = (e) => {
    const text = e.target.value ? e.target.value : null;
    setTokenSymbolError("");
    setTokenSymbol(text);
  };

  const onChangeBackingTokens = (e, index) => {
    setBackingTokensError("");
    const text = e.target.value ? e.target.value : null;
    setBackingTokens((prev) => {
      prev[index] = text;
      return prev;
    });
  };

  const onChangeBackingAmounts = (e, index) => {
    const text = e.target.value ? e.target.value : null;
    setBackingAmountsError("");
    setBackingAmounts((prev) => {
      prev[index] = text ? text.toString() : text;
      return prev;
    });
  };

  const onChangeBackingTokensV3Fee = (e, index) => {
    if (
      e.target.value === "0.05" ||
      e.target.value === "0.3" ||
      e.target.value === "1"
    ) {
      setBackingTokensV3Fee((prev) => {
        prev[index] = ethers.utils.parseUnits(e.target.value, 4);
        return prev;
      });
    }
    return;
  };

  const onChangeTotalSupply = (e) => {
    const text = e.target.value ? e.target.value : null;
    setTotalSupplyError("");
    setTotalSupply(text);
  };

  const onChangeStakeMinPercentage = (e) => {
    const text = e.target.value ? e.target.value : null;
    setStakeMinPercentageError("");
    setStakeMinPercentage(text);
  };

  const createRebaseToken = async () => {
    if (!isWrongChain) {
      if (signer) {
        if (signer.provider._network.chainId === 1) {
          let isGoodToGo = true;

          if (!ownerAddress) {
            setOwnerAddressError("Can't be empty");
            isGoodToGo = false;
          } else if (!ethers.utils.isAddress(ownerAddress)) {
            setOwnerAddressError("Invalid address");
            isGoodToGo = false;
          }

          if (!liquidity) {
            setLiquidityError("Can't be empty");
            isGoodToGo = false;
          } else if (!isNumber(liquidity)) {
            setLiquidityError("Must be a number");
            isGoodToGo = false;
          } else if (liquidity < 0.5) {
            setLiquidityError("Min liquidity is 0.5 ETH");
            isGoodToGo = false;
          } else if (
            addressEthBalance.lte(ethers.utils.parseEther(liquidity.toString()))
          ) {
            setLiquidityError("Not enough ETH");
            isGoodToGo = false;
          }

          if (!creatorSupplyPercentage) {
            setCreatorSupplyPercentageError("Can't be empty");
            isGoodToGo = false;
          } else if (!isNumber(creatorSupplyPercentage)) {
            setCreatorSupplyPercentageError("Must be a number");
            isGoodToGo = false;
          } else if (Number(creatorSupplyPercentage) > 10) {
            setCreatorSupplyPercentageError("Max is 10%");
            isGoodToGo = false;
          }

          const newBackingTokens = backingTokens;
          if (
            newBackingTokens.filter((i) => i === null || i === "").length > 0
          ) {
            setBackingTokensError("Can't left any field empty");
            isGoodToGo = false;
          } else if (
            newBackingTokens.filter((i) => !ethers.utils.isAddress(i)).length >
            0
          ) {
            setBackingTokensError("Invalid Address");
            isGoodToGo = false;
          }

          const newBackingAmounts = backingAmounts;
          if (
            newBackingAmounts.filter((i) => i === null || i === "").length > 0
          ) {
            setBackingAmountsError("Can't left any field empty");
            isGoodToGo = false;
          } else if (newBackingAmounts.filter((i) => !isNumber(i)).length > 0) {
            setBackingAmountsError("Amount must be a number");
            isGoodToGo = false;
          }

          if (!tokenName) {
            setTokenNameError("Can't be empty");
            isGoodToGo = false;
          }

          if (!tokenSymbol) {
            setTokenSymbolError("Can't be empty");
            isGoodToGo = false;
          }

          if (isGoodToGo) {
            //reset all errors
            setBackingAmountsError("");
            setBackingTokensError("");
            setCreatorSupplyPercentageError("");
            setTokenNameError("");
            setTokenSymbolError("");
            setOwnerAddressError("");
            setLiquidityError("");

            const contract = new ethers.Contract(
              rebaseQwaAddressEth,
              rebaseQwaAbi,
              signer
            );
            try {
              const options = {
                value: ethers.utils.parseEther(liquidity.toString()),
              };
              let response = await contract.create(
                backingTokens,
                backingTokensV3Fee,
                backingAmounts,
                [tokenName, tokenSymbol],
                creatorSupplyPercentage,
                ownerAddress,
                options
              );
              toast.promise(response.wait(1), {
                pending: "Transaction Pending...",
                success: `Token Created!`,
                error: "Transcation failed",
              });

              const receipt = await response.wait(1);
              if (receipt.status) {
                setIsSuccessPage(true);
                setNewTokenCreated(receipt.to);
              }
            } catch (error) {
              console.log(error);
              if (error.data?.message?.includes("insufficient funds for gas")) {
                toast.error("Not enough eth for gas");
              } else {
                toast.error(`${error.code.toString().replace("_", " ")}`);
              }
            }
          }
        }
      } else {
        toast.error("Signer Doesn't Exist");
      }
    } else {
      toast.error("Wrong Chain");
    }
  };

  const createNonRebaseToken = async () => {
    if (!isWrongChain) {
      if (signer) {
        if (signer.provider._network.chainId === 1) {
          let isGoodToGo = true;

          if (!ownerAddress) {
            setOwnerAddressError("Can't be empty");
            isGoodToGo = false;
          } else if (!ethers.utils.isAddress(ownerAddress)) {
            setOwnerAddressError("Invalid address");
            isGoodToGo = false;
          }

          if (!liquidity) {
            setLiquidityError("Can't be empty");
            isGoodToGo = false;
          } else if (!isNumber(liquidity)) {
            setLiquidityError("Must be a number");
            isGoodToGo = false;
          } else if (liquidity < 0.5) {
            setLiquidityError("Min liquidity is 0.5 ETH");
            isGoodToGo = false;
          } else if (
            addressEthBalance.lte(ethers.utils.parseEther(liquidity.toString()))
          ) {
            setLiquidityError("Not enough ETH");
            isGoodToGo = false;
          }

          if (!creatorSupplyPercentage) {
            setCreatorSupplyPercentageError("Can't be empty");
            isGoodToGo = false;
          } else if (!isNumber(creatorSupplyPercentage)) {
            setCreatorSupplyPercentageError("Must be a number");
            isGoodToGo = false;
          } else if (Number(creatorSupplyPercentage) > 10) {
            setCreatorSupplyPercentageError("Max is 10%");
            isGoodToGo = false;
          }

          const newBackingTokens = backingTokens;
          if (
            newBackingTokens.filter((i) => i === null || i === "").length > 0
          ) {
            setBackingTokensError("Can't left any field empty");
            isGoodToGo = false;
          } else if (
            newBackingTokens.filter((i) => !ethers.utils.isAddress(i)).length >
            0
          ) {
            setBackingTokensError("Invalid Address");
            isGoodToGo = false;
          }

          if (!tokenName) {
            setTokenNameError("Can't be empty");
            isGoodToGo = false;
          }

          if (!tokenSymbol) {
            setTokenSymbolError("Can't be empty");
            isGoodToGo = false;
          }

          if (!totalSupply) {
            setTotalSupplyError("Can't be empty");
            isGoodToGo = false;
          } else if (!isNumber(totalSupply)) {
            setTotalSupplyError("Must be a number");
            isGoodToGo = false;
          }

          if (!stakeMinPercentage) {
            setStakeMinPercentageError("Can't be empty");
            isGoodToGo = false;
          } else if (!isNumber(stakeMinPercentage)) {
            setStakeMinPercentageError("Must be a number");
            isGoodToGo = false;
          } else if (
            ethers.utils
              .parseEther(stakeMinPercentage.toString())
              .gt(ethers.utils.parseEther("0.25"))
          ) {
            setStakeMinPercentageError("Max is 0.25%");
            isGoodToGo = false;
          }

          if (isGoodToGo) {
            //reset all errors
            setBackingTokensError("");
            setCreatorSupplyPercentageError("");
            setTokenNameError("");
            setTokenSymbolError("");
            setOwnerAddressError("");
            setLiquidityError("");
            setTotalSupplyError("");
            setTotalSupplyError("");
            setStakeMinPercentageError("");

            const contract = new ethers.Contract(
              nonRebaseQwaAddressEth,
              nonRebaseQwaAbi,
              signer
            );
            try {
              const options = {
                value: ethers.utils.parseEther(liquidity.toString()),
              };
              let response = await contract.create(
                backingTokens,
                backingTokensV3Fee,
                [tokenName, tokenSymbol],
                creatorSupplyPercentage,
                ethers.utils.parseUnits(totalSupply, 9),
                ethers.utils.parseUnits(stakeMinPercentage, 2),
                ownerAddress,
                options
              );
              toast.promise(response.wait(1), {
                pending: "Transaction Pending...",
                success: `Token Created!`,
                error: "Transcation failed",
              });

              const receipt = await response.wait(1);
              if (receipt.status) {
                setIsSuccessPage(true);
                setNewTokenCreated(receipt.to);
              }
            } catch (error) {
              console.log(error);
              if (error.data?.message?.includes("insufficient funds for gas")) {
                toast.error("Not enough eth for gas");
              } else {
                toast.error(`${error.code.toString().replace("_", " ")}`);
              }
            }
          }
        }
      } else {
        toast.error("Signer Doesn't Exist");
      }
    } else {
      toast.error("Wrong Chain");
    }
  };

  const createRebaseTokenBase = async () => {
    if (!isWrongChain) {
      if (signer) {
        if (signer.provider._network.chainId === 8453) {
          let isGoodToGo = true;

          if (!ownerAddress) {
            setOwnerAddressError("Can't be empty");
            isGoodToGo = false;
          } else if (!ethers.utils.isAddress(ownerAddress)) {
            setOwnerAddressError("Invalid address");
            isGoodToGo = false;
          }

          if (!liquidity) {
            setLiquidityError("Can't be empty");
            isGoodToGo = false;
          } else if (!isNumber(liquidity)) {
            setLiquidityError("Must be a number");
            isGoodToGo = false;
          } else if (liquidity < 0.5) {
            setLiquidityError("Min liquidity is 0.5 ETH");
            isGoodToGo = false;
          } else if (
            addressEthBalance.lte(ethers.utils.parseEther(liquidity.toString()))
          ) {
            setLiquidityError("Not enough ETH");
            isGoodToGo = false;
          }

          if (!creatorSupplyPercentage) {
            setCreatorSupplyPercentageError("Can't be empty");
            isGoodToGo = false;
          } else if (!isNumber(creatorSupplyPercentage)) {
            setCreatorSupplyPercentageError("Must be a number");
            isGoodToGo = false;
          } else if (Number(creatorSupplyPercentage) > 10) {
            setCreatorSupplyPercentageError("Max is 10%");
            isGoodToGo = false;
          }

          const newBackingTokens = backingTokens;
          if (
            newBackingTokens.filter((i) => i === null || i === "").length > 0
          ) {
            setBackingTokensError("Can't left any field empty");
            isGoodToGo = false;
          } else if (
            newBackingTokens.filter((i) => !ethers.utils.isAddress(i)).length >
            0
          ) {
            setBackingTokensError("Invalid Address");
            isGoodToGo = false;
          }

          const newBackingAmounts = backingAmounts;
          if (
            newBackingAmounts.filter((i) => i === null || i === "").length > 0
          ) {
            setBackingAmountsError("Can't left any field empty");
            isGoodToGo = false;
          } else if (newBackingAmounts.filter((i) => !isNumber(i)).length > 0) {
            setBackingAmountsError("Amount must be a number");
            isGoodToGo = false;
          }

          if (!tokenName) {
            setTokenNameError("Can't be empty");
            isGoodToGo = false;
          }

          if (!tokenSymbol) {
            setTokenSymbolError("Can't be empty");
            isGoodToGo = false;
          }

          if (isGoodToGo) {
            //reset all errors
            setBackingAmountsError("");
            setBackingTokensError("");
            setCreatorSupplyPercentageError("");
            setTokenNameError("");
            setTokenSymbolError("");
            setOwnerAddressError("");
            setLiquidityError("");

            const contract = new ethers.Contract(
              rebaseQwaAddressBase,
              rebaseQwaBaseAbi,
              signer
            );
            try {
              const options = {
                value: ethers.utils.parseEther(liquidity.toString()),
              };
              let response = await contract.create(
                backingTokens,
                backingAmounts,
                [tokenName, tokenSymbol],
                creatorSupplyPercentage,
                ownerAddress,
                options
              );
              toast.promise(response.wait(1), {
                pending: "Transaction Pending...",
                success: `Token Created!`,
                error: "Transcation failed",
              });

              const receipt = await response.wait(1);
              if (receipt.status) {
                setIsSuccessPage(true);
                setNewTokenCreated(receipt.to);
              }
            } catch (error) {
              console.log(error);
              if (error.data?.message?.includes("insufficient funds for gas")) {
                toast.error("Not enough eth for gas");
              } else {
                toast.error(`${error.code.toString().replace("_", " ")}`);
              }
            }
          }
        }
      } else {
        toast.error("Signer Doesn't Exist");
      }
    } else {
      toast.error("Wrong Chain");
    }
  };

  const createNonRebaseTokenBase = async () => {
    if (!isWrongChain) {
      if (signer) {
        if (signer.provider._network.chainId === 8453) {
          let isGoodToGo = true;

          if (!ownerAddress) {
            setOwnerAddressError("Can't be empty");
            isGoodToGo = false;
          } else if (!ethers.utils.isAddress(ownerAddress)) {
            setOwnerAddressError("Invalid address");
            isGoodToGo = false;
          }

          if (!liquidity) {
            setLiquidityError("Can't be empty");
            isGoodToGo = false;
          } else if (!isNumber(liquidity)) {
            setLiquidityError("Must be a number");
            isGoodToGo = false;
          } else if (liquidity < 0.5) {
            setLiquidityError("Min liquidity is 0.5 ETH");
            isGoodToGo = false;
          } else if (
            addressEthBalance.lte(ethers.utils.parseEther(liquidity.toString()))
          ) {
            setLiquidityError("Not enough ETH");
            isGoodToGo = false;
          }

          if (!creatorSupplyPercentage) {
            setCreatorSupplyPercentageError("Can't be empty");
            isGoodToGo = false;
          } else if (!isNumber(creatorSupplyPercentage)) {
            setCreatorSupplyPercentageError("Must be a number");
            isGoodToGo = false;
          } else if (Number(creatorSupplyPercentage) > 10) {
            setCreatorSupplyPercentageError("Max is 10%");
            isGoodToGo = false;
          }

          const newBackingTokens = backingTokens;
          if (
            newBackingTokens.filter((i) => i === null || i === "").length > 0
          ) {
            setBackingTokensError("Can't left any field empty");
            isGoodToGo = false;
          } else if (
            newBackingTokens.filter((i) => !ethers.utils.isAddress(i)).length >
            0
          ) {
            setBackingTokensError("Invalid Address");
            isGoodToGo = false;
          }

          if (!tokenName) {
            setTokenNameError("Can't be empty");
            isGoodToGo = false;
          }

          if (!tokenSymbol) {
            setTokenSymbolError("Can't be empty");
            isGoodToGo = false;
          }

          if (!totalSupply) {
            setTotalSupplyError("Can't be empty");
            isGoodToGo = false;
          } else if (!isNumber(totalSupply)) {
            setTotalSupplyError("Must be a number");
            isGoodToGo = false;
          }

          if (!stakeMinPercentage) {
            setStakeMinPercentageError("Can't be empty");
            isGoodToGo = false;
          } else if (!isNumber(stakeMinPercentage)) {
            setStakeMinPercentageError("Must be a number");
            isGoodToGo = false;
          } else if (
            ethers.utils
              .parseEther(stakeMinPercentage.toString())
              .gt(ethers.utils.parseEther("0.25"))
          ) {
            setStakeMinPercentageError("Max is 0.25%");
            isGoodToGo = false;
          }

          if (isGoodToGo) {
            //reset all errors
            setBackingTokensError("");
            setCreatorSupplyPercentageError("");
            setTokenNameError("");
            setTokenSymbolError("");
            setOwnerAddressError("");
            setLiquidityError("");
            setTotalSupplyError("");
            setTotalSupplyError("");
            setStakeMinPercentageError("");

            const contract = new ethers.Contract(
              nonRebaseQwaAddressBase,
              nonRebaseQwaBaseAbi,
              signer
            );
            try {
              const options = {
                value: ethers.utils.parseEther(liquidity.toString()),
              };
              let response = await contract.create(
                backingTokens,
                [tokenName, tokenSymbol],
                creatorSupplyPercentage,
                ethers.utils.parseUnits(totalSupply, 9),
                ethers.utils.parseUnits(stakeMinPercentage, 2),
                ownerAddress,
                options
              );
              toast.promise(response.wait(1), {
                pending: "Transaction Pending...",
                success: `Token Created!`,
                error: "Transcation failed",
              });

              const receipt = await response.wait(1);
              if (receipt.status) {
                setIsSuccessPage(true);
                setNewTokenCreated(receipt.to);
              }
            } catch (error) {
              console.log(error);
              if (error.data?.message?.includes("insufficient funds for gas")) {
                toast.error("Not enough eth for gas");
              } else {
                toast.error(`${error.code.toString().replace("_", " ")}`);
              }
            }
          }
        }
      } else {
        toast.error("Signer Doesn't Exist");
      }
    } else {
      toast.error("Wrong Chain");
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(currentAccount);
    toast.success(`Address Copied`);
    //setCopySuccess("Copied!");
  };

  return (
    <div className="Launcher">
      <div className="container">
        <div className="title-container">
          <h1>Launcher</h1>
        </div>

        <div className="body-wrapper">
          {signer && !isWrongChain ? (
            <>
              <div className="body-container">
                <div className="single-box">
                  <h3>Current Account:</h3>
                  <p>{currentAccount}</p>
                  <div className="ml5">
                    <div className="winXP-wrap">
                      <button
                        className="winXP-btn"
                        onClick={() => copyToClipboard()}
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
                <div className="single-box">
                  <h3>Balance:</h3>
                  <p>{toFixedNoRoundParseEther(addressEthBalance)} ETH</p>
                </div>
              </div>
              {!isSuccessPage ? (
                <>
                  <div className="body-container">
                    <div className="single-box">
                      <h3>Create Token:</h3>
                    </div>
                    <div className="single-box">
                      <>
                        <h4>Chain:</h4>
                        <input
                          type="radio"
                          id="ethereum"
                          name="chain"
                          value="ethereum"
                          className="ml3"
                          checked={signer.provider._network.chainId === 1}
                          onChange={() => onSwitchNetwork(1)}
                        />
                        <label for="html">Ethereum</label>
                        <input
                          type="radio"
                          id="base"
                          name="chain"
                          value="base"
                          className="ml3"
                          checked={signer.provider._network.chainId === 8453}
                          onChange={() => onSwitchNetwork(8453)}
                        />
                        <label for="css">Base</label>
                      </>
                    </div>
                    <div className="single-box">
                      <>
                        <h4>Token Type:</h4>
                        <input
                          type="radio"
                          id="rebase"
                          name="token_type"
                          value="rebase"
                          className="ml3"
                          checked={tokenType === "rebase"}
                          onChange={(e) => onTokenTypeChange(e)}
                        />
                        <label for="html">Rebase</label>
                        <input
                          type="radio"
                          id="non_rebase"
                          name="token_type"
                          value="non_rebase"
                          className="ml3"
                          checked={tokenType === "non_rebase"}
                          onChange={(e) => onTokenTypeChange(e)}
                        />
                        <label for="css">Non Rebase</label>
                      </>
                    </div>
                    <div className="single-box">
                      <h4>Owner Address:</h4>
                      <input
                        onChange={(e) => {
                          onChangeOwnerAddress(e);
                        }}
                        type="text"
                      />
                      <p className="ml3 error">{ownerAddressError}</p>
                    </div>
                    <div className="single-box">
                      <h4>Name:</h4>
                      <input
                        onChange={(e) => {
                          onChangeTokenName(e);
                        }}
                        type="text"
                      />
                      <p className="ml3 error">{tokenNameError}</p>
                    </div>
                    <div className="single-box">
                      <h4>Symbol:</h4>
                      <input
                        onChange={(e) => {
                          onChangeTokenSymbol(e);
                        }}
                        type="text"
                      />
                      <p className="ml3 error">{tokenSymbolError}</p>
                    </div>
                    <div className="single-box">
                      <h4>Liquidity (ETH):</h4>
                      <input
                        onChange={(e) => {
                          onChangeLiquidity(e);
                        }}
                        type="number"
                      />
                      <p className="ml3 error">{liquidityError}</p>
                    </div>
                    <div className="single-box">
                      <h4>Backing Tokens:</h4>

                      <div className="add-remove-buttons">
                        {currentBackingTokensNumber > 1 && (
                          <button onClick={() => onClickRemoveInput()}>
                            -
                          </button>
                        )}
                        <button onClick={() => onClickAddInput()}>+</button>
                      </div>
                      <p className="ml3 error">
                        {backingTokensError
                          ? backingTokensError
                          : tokenType === "rebase" && backingAmountsError}
                      </p>
                    </div>
                    {Array(currentBackingTokensNumber)
                      .fill("")
                      .map((i, index) => (
                        <>
                          <div className="single-box ml5">
                            <h4>{index + 1}.</h4>
                            <p className="ml3">Token Address:</p>
                            <input
                              onChange={(e) => {
                                onChangeBackingTokens(e, index);
                              }}
                              type="text"
                            />
                            {tokenType === "rebase" && (
                              <>
                                <p className="ml3">
                                  Amount (on token decimals):
                                </p>
                                <input
                                  onChange={(e) => {
                                    onChangeBackingAmounts(e, index);
                                  }}
                                  type="text"
                                />
                              </>
                            )}
                            {signer.provider._network.chainId === 1 && (
                              <>
                                {" "}
                                <p className="ml3">LP Type:</p>
                                <input
                                  type="radio"
                                  id="v2"
                                  name={`dex_type_${index}`}
                                  value="v2"
                                  checked={dexType[index] === "v2"}
                                  onChange={(e) => onOptionChange(e, index)}
                                />
                                <label for="html">V2</label>
                                <input
                                  type="radio"
                                  id="v3"
                                  name={`dex_type_${index}`}
                                  value="v3"
                                  checked={dexType[index] === "v3"}
                                  onChange={(e) => onOptionChange(e, index)}
                                />
                                <label for="css">V3</label>
                              </>
                            )}
                          </div>
                          {signer.provider._network.chainId === 1 && (
                            <div className="single-box">
                              {dexType[index] === "v3" && (
                                <>
                                  <p className="ml10">V3 Fee:</p>
                                  <input
                                    type="radio"
                                    id="0.05"
                                    name={`v3_fee_${index}`}
                                    value="0.05"
                                    className="ml3"
                                    checked={backingTokensV3Fee[index].eq(
                                      ethers.utils.parseUnits("0.05", 4)
                                    )}
                                    onChange={(e) =>
                                      onChangeBackingTokensV3Fee(e, index)
                                    }
                                  />
                                  <label for="html">0.05%</label>
                                  <input
                                    type="radio"
                                    id="0.3"
                                    name={`v3_fee_${index}`}
                                    value="0.3"
                                    className="ml3"
                                    checked={backingTokensV3Fee[index].eq(
                                      ethers.utils.parseUnits("0.3", 4)
                                    )}
                                    onChange={(e) =>
                                      onChangeBackingTokensV3Fee(e, index)
                                    }
                                  />
                                  <label for="css">0.3%</label>
                                  <input
                                    type="radio"
                                    id="1"
                                    name={`v3_fee_${index}`}
                                    value="1"
                                    className="ml3"
                                    checked={backingTokensV3Fee[index].eq(
                                      ethers.utils.parseUnits("1", 4)
                                    )}
                                    onChange={(e) =>
                                      onChangeBackingTokensV3Fee(e, index)
                                    }
                                  />
                                  <label for="css">1%</label>
                                </>
                              )}
                            </div>
                          )}
                        </>
                      ))}

                    <div className="single-box">
                      <h4>Creator Supply Percentage:</h4>
                      <input
                        onChange={(e) => {
                          onChangeCreatorSupplyPercentage(e);
                        }}
                        type="number"
                      />
                      <p className="ml3 error">
                        {creatorSupplyPercentageError}
                      </p>
                    </div>
                    {tokenType === "non_rebase" && (
                      <>
                        <div className="single-box">
                          <h4>Total Supply:</h4>
                          <input
                            onChange={(e) => {
                              onChangeTotalSupply(e);
                            }}
                            type="number"
                          />
                          <p className="ml3 error">{totalSupplyError}</p>
                        </div>
                        <div className="single-box">
                          <h4>Stake Min Percentage:</h4>
                          <input
                            onChange={(e) => {
                              onChangeStakeMinPercentage(e);
                            }}
                            type="number"
                          />
                          <p className="ml3 error">{stakeMinPercentageError}</p>
                        </div>
                        <div className="single-box">
                          <p>max: 0.25%</p>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="winXP-wrap">
                    <button
                      className="winXP-btn"
                      onClick={
                        tokenType === "rebase"
                          ? signer.provider._network.chainId === 1
                            ? () => createRebaseToken()
                            : signer.provider._network.chainId === 8453 &&
                              (() => createRebaseTokenBase())
                          : tokenType === "non_rebase" &&
                            signer.provider._network.chainId === 1
                          ? () => createNonRebaseToken()
                          : signer.provider._network.chainId === 8453 &&
                            (() => createNonRebaseTokenBase())
                      }
                    >
                      Create
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="body-container">
                    <div className="single-box">
                      <h3>${tokenSymbol} CREATED!</h3>
                    </div>
                    <div className="single-box">
                      <h4>Contract Address:</h4>
                      <p>{newTokenCreated}</p>
                    </div>
                  </div>
                  <div className="winXP-wrap">
                    <button
                      className="winXP-btn"
                      onClick={() => setIsSuccessPage(false)}
                    >
                      Create Another
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="body-container">
              <ConnectButton isFooter={false} isWrongChain={isWrongChain} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
