import React, { useState } from "react";
import { ethers, BigNumber } from "ethers";
import styled from "styled-components";
import "./dashboard.css";

const toFixedNoRoundParse = (number, precision = 4) => {
  number = Number(ethers.utils.formatUnits(number, 9));
  const factor = Math.pow(10, precision);
  const result = Math.floor(number * factor) / factor;

  if (result === 0) {
    return "0.0";
  }
  return result;
};

const toFixedNoRoundParseUnit = (number, unit, precision = 18) => {
  number = ethers.utils.formatUnits(number, unit);
  number = number.split(".");
  const result = `${number[0]}.${number[1].substring(0, 2)}`;
  if (result === "0.00") {
    return "0.0";
  }
  return Number(result).toLocaleString("en-US");
};

const toFixedNoRoundParseEther = (number, precision = 4) => {
  number = Number(ethers.utils.formatUnits(number, 18));
  const factor = Math.pow(10, precision);
  const result = Math.floor(number * factor) / factor;

  if (result === 0) {
    return "0.0";
  }
  return result;
};

export default function Dashboard({
  onClose,
  tokenPriceInUsd,
  totalUsdLiquidity,
  marketCap,
  treasuryTotalUsd,
  treasuryLp,
  treasurySqwn,
  treasuryWeth,
}) {
  return (
    <div className="Dashboard">
      <div className="container">
        <div className="title-container">
          <h1>Dashboard</h1>
        </div>

        <div className="body-wrapper">
          <div className="body-container">
            <div className="single-box">
              <h3>$QWN Price:</h3>
              {tokenPriceInUsd !== null && (
                <p>${toFixedNoRoundParseUnit(tokenPriceInUsd, 59)}</p>
              )}
            </div>
          </div>
          <div className="body-container">
            <div className="single-box">
              <h3>$QWN Market Cap:</h3>
              {marketCap !== null && (
                <p>${toFixedNoRoundParseUnit(marketCap, 68)}</p>
              )}
            </div>
          </div>
          <div className="body-container">
            <div className="single-box">
              <h3>Total Liquidity:</h3>
              {totalUsdLiquidity !== null && (
                <p>${toFixedNoRoundParseUnit(totalUsdLiquidity, 20)}</p>
              )}
            </div>
          </div>
          <div className="body-container">
            <div className="single-box">
              <h3>Treasury:</h3>
            </div>
            <div className="single-box ml10">
              {treasuryWeth !== null && (
                <p className="mr3">- {toFixedNoRoundParseUnit(treasuryWeth, 18)}</p>
              )}
              <h4>WETH</h4>
            </div>
            <div className="single-box ml10">
              {treasurySqwn !== null && (
                <p className="mr3">- {toFixedNoRoundParseUnit(treasurySqwn, 9)}</p>
              )}
              <h4>SQWN</h4>
            </div>
            <div className="single-box ml10">
              {treasuryLp !== null && (
                <p className="mr3">- {toFixedNoRoundParseEther(treasuryLp)}</p>
              )}
              <h4>LP</h4>
            </div>
            <div className="single-box ml10">
              <h4>Total:</h4>
              {treasuryTotalUsd !== null && (
                <p>${toFixedNoRoundParseUnit(treasuryTotalUsd, 68)}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
